<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 13.249 13.248"
  >
    <path
      :style="iconStyle"
      d="M2.966,13.249H.219V4.4H2.966ZM1.591,3.2A1.6,1.6,0,1,1,3.181,1.591,1.6,1.6,0,0,1,1.591,3.2ZM13.246,13.249H10.5V8.943c0-1.026-.021-2.342-1.428-2.342C7.649,6.6,7.43,7.716,7.43,8.869v4.38H4.686V4.4H7.32V5.61h.038a2.886,2.886,0,0,1,2.6-1.428c2.78,0,3.291,1.831,3.291,4.208v4.859Z"
      transform="translate(0 0)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '13.249',
    },
    width: {
      type: String,
      default: '13.249',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
